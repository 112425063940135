import { useState, useEffect } from "react";

const quotes = [
  "Inspiration is the root of creativity.",
  "What you once knew evolves with every discovery.",
  "The biggest challenge in this life is to drop everything that you once believed in.",
  "In shadows, we find the light that others cannot see.",
  "Wisdom is not in knowing, but in the journey of discovery.",
  "Time is but an illusion; legacy, the eternal truth.",
  "From the silent whispers of history, our brotherhood emerges.",
  "Secrets are the currency of the enlightened.",
  "Where society sees an end, we see a beginning.",
  "In every myth, a grain of truth awaits the seeker.",
  "Only when the veil is lifted, does the world's true face appear.",
  "To be lost is but a step on the path to being found.",
  "The world speaks in codes, waiting for the few who listen.",
  "Within the folds of the known, the unknown patiently lingers.",
  "With each dawn, ancient echoes find a voice anew.",
  "Beyond the realms of the seen lies the vast expanse of the unfathomable.",
  "Knowledge, like water, seeps into hidden places, awaiting the seeker.",
  "Between every pause in history, whispers of forgotten lore beckon.",
  "Beneath the surface of every tale lies a deeper truth.",
  "Every shadow holds a story waiting to be illuminated.",
  "In silence, what song does the deaf heart hear?",
  "In darkness, what masterpiece does the blind eye envision?",
];

function RotatingQuotes() {
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
    }, 6500);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return <div id="quoteContainer">{quotes[currentQuoteIndex]}</div>;
}

export default RotatingQuotes;
