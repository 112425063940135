import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

const CanonicalHandler = ({
  title = "The Brotherhood - Beyond The Veil Of Reality",
  description = "Beyond The Veil Of Reality",
}) => {
  const location = useLocation();
  const canonicalUrl = `https://thebrotherhood.foundation${location.pathname}`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

CanonicalHandler.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default CanonicalHandler;
