import { useState } from "react";
import { Modal, Button } from "react-bootstrap"; // Using Bootstrap's Modal directly
import "./LoginRegister.css"; // Custom styles
import { API_BASE_URL } from "../../apiConfig";
import PropTypes from "prop-types";
import { jwtDecode } from "jwt-decode"; // Add this for decoding the token

const LoginPage = ({
  isOpen = false,
  onClose = () => {},
  setIsAuthenticated,
  setUserRole, // Add this here
  toggleToRegister = () => {},
}) => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false); // Track "Keep Me Logged In"
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Handle form submission
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    await loginUser({ login, password, keepMeLoggedIn });
  };

  // Function to log in the user
  const loginUser = async (loginData) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/LoginPage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });

      if (!response.ok) {
        const data = await response.json();
        setErrorMessage(data.message || "Invalid credentials.");
      } else {
        const data = await response.json();

        // Store token in appropriate storage based on "Keep Me Logged In"
        const storage = loginData.keepMeLoggedIn
          ? localStorage
          : sessionStorage;

        storage.setItem("authToken", data.token);

        // Decode the token to get user role
        const decodedToken = jwtDecode(data.token);
        setUserRole(decodedToken.role); // Set the user role based on the token

        // Update authentication state
        setIsAuthenticated(true);
        onClose(); // Close the modal upon successful login
      }
    } catch (error) {
      setErrorMessage("Unexpected error occurred.");
    }
    setIsLoading(false);
  };

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <button className="modal-close" onClick={onClose}>
        &times;
      </button>
      <Modal.Title>Access the Chamber</Modal.Title>

      <Modal.Body>
        <form className="user-form" onSubmit={handleLoginSubmit}>
          <input
            type="text"
            autoComplete="username"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            placeholder="Epithet"
            required
          />
          <input
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Chamber Key"
            required
          />

          {/* "Keep Me Logged In" Checkbox */}
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="keepMeLoggedIn"
              checked={keepMeLoggedIn}
              onChange={(e) => setKeepMeLoggedIn(e.target.checked)} // Update state
            />
            <label htmlFor="keepMeLoggedIn">Keep Me Logged In</label>
          </div>

          <input type="submit" value="Access" />
          {errorMessage && (
            <div className="message userreg-error-message">{errorMessage}</div>
          )}
          {isLoading && <div>Decrypting Chamber key...</div>}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="logreg-button"
          variant="link"
          onClick={toggleToRegister}
        >
          Become A Brother
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

LoginPage.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  setIsAuthenticated: PropTypes.func.isRequired,
  setUserRole: PropTypes.func.isRequired, // Add this here
  toggleToRegister: PropTypes.func,
};

export default LoginPage;
